body {
  font-family: 'Norwester', 'Bebas Neue', sans-serif;
  background-image: url(./images/initials-bg.png);
    background-position: top center;
    background-position-x: center;
    background-position-y: top;
    background-repeat: repeat;
    background-repeat-x: repeat;
    background-repeat-y: repeat;
    background-size: 60% auto;
    background-attachment: fixed;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

main {
  flex: 1 0 auto;
}

.root {
  flex: 1 0 auto;
}

/* Initials Color Theme Swatches in RGBA */

.initials-red { color: rgba(235, 20, 49, 1); }
.initials-blue { color: rgba(36, 57, 94, 1); }
.initials-black { color: rgba(0, 0, 0, 1); }
.initials-white { color: rgba(233, 232, 232, 1); }
.initials-green { color: rgba(14, 189, 35, 1); }


.loading {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  text-align: center;
}

@font-face {
  font-family: 'Norwester';
  src: local('Norwester'), url(./fonts/norwester.woff) format('woff');
}

@font-face {
  font-family: 'Scoreboard';
  src: local('Scoreboard'), url(./fonts/scoreboard.woff) format('woff');
}

h1 {
  font-family: 'Scoreboard','Norwester', 'Bebas Neue', sans-serif;
  font-size: 3em;
  margin-top: 0.33em;
  color: #e51d23;
}

h2 {
  font-family: 'Scoreboard','Norwester', 'Bebas Neue', sans-serif;
  font-size: 5em;
  margin-top: 0;
  color: #e51d23;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-logo-nav {
  height: 40vax;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.logo-header {
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}


.loading {
  min-height: 53vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  padding: 5vh 0 2vh;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.boxtop {
  min-height: 20vh;
  background-color: rgba(36, 57, 94, 1);
}

.boxbottom {
  min-height: 20vh;
  background-color: rgba(36, 57, 94, 1);

}